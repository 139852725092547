import React, {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import translations from '../data/lang.json';
import {Settings} from "../store";
import Card from "./Card";
import {SpreadGrid} from '../hooks/spreadGrid';
import {MasonryGrid, JustifiedGrid, FrameGrid, PackingGrid} from "@egjs/react-grid";

const Sectorsv2 = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {investmentValue, negativeCalculation} = props;
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const [activeID, setActiveID] = useState(null);
    const [totalImpactValue, setTotalImpactValue] = useState(0);
    const allIndustries = [
        {
            id: 1,
            name: 'Remaining Sectors',
            companies: 1256,
            value: 108168850813,
            img: '',
            grid: "1 / 9 / 13 / 12",
            order: 1
        },
        {
            id: 2,
            name: 'Utilities',
            companies: 45,
            value: 88767971000,
            img: 'iron.jpg',
            grid: "1 / 6 / 10 / 9"
        },
        {
            id: 3,
            name: 'Metal Ore Mining',
            companies: 23,
            value: 48634935000,
            img: 'wood.jpg',
            grid: "1 / 3 / 5 / 6"
        },
        {
            id: 4,
            name: 'Oil and Gas',
            companies: 54,
            value: 48577245000,
            img: 'aluminum.jpg',
            grid: "5 / 3 / 9 / 6"

        },
        {
            id: 5,
            name: 'Iron and Steel',
            companies: 9,
            value: 32612710000,
            img: 'iron.jpg',
            grid: "1 / 1 / 5 / 3"
        },
        {
            id: 6,
            name: 'Performance and Industrial Chemical',
            companies: 47,
            value: 27113803800,
            img: 'air.jpg',
            grid: "5 / 1 / 9 / 3"
        },
        {
            id: 7,
            name: 'Cement',
            companies: 6,
            value: 19685550000,
            img: 'coal.jpg',
            grid: "10 / 6 / 13 / 9"
        },
        {
            id: 8,
            name: 'Air Logistics',
            companies: 9,
            value: 16124207100,
            img: 'metal.jpg',
            grid: "9 / 4 / 13 / 6"

        },
        {
            id: 9,
            name: 'Conglomerates',
            companies: 29,
            value: 12100521180,
            img: 'water.jpg',
            grid: "9 / 2 / 13 / 4"
        },

    ];


    const staticValue = {
        all: {
            height: 316982,
            maxwh: 200,
            fontDivider: 7,
            maxVal: 216982
        },
        largestShare: '<span class="word"><span>Utilities -</span></span> <span class="word"><span>Generation</span></span> <span class="word"><span>and Distribution</span></span>',
    }

    const [cardsLoader, setCardsLoader] = useState(false)

    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);

    useEffect(() => {
        elGroup('.grid').forEach((item) => {
            gsap.set(item, {
                height: item.clientHeight
            })
        })
        gsap.set(elGroup('.grid__item'), {
            scale: 0,
            rotation: 0,
            x: 0,
            y: 0,
            opacity: 0
        });
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: "top 50%",
                end: "bottom",
                scrub: false
            },
        })
        let delay = 0.05
        let sum = 0;
        allIndustries.forEach((item, index) => {
            sum += item.value
            tl.to(elGroup('.grid__item')[index], {
                opacity: 1,
                delay: delay,
                scale: 1,
                duration: 0.5,
                onComplete: () => {
                    if (index === 1) {
                        if (window.innerWidth > 992) {
                            elGroup('.grid__item')[index].classList.add('active')
                            elGroup('.grid__item')[index].click();
                        }
                    }
                }
            }, 'same')

            delay += 0.05;
        })
        setTotalImpactValue(sum)
    }, [window.innerWidth]);
    useEffect(() => {
        if (window.innerWidth > 992) {
            [...elGroup('.grid')].forEach(grid => new SpreadGrid(grid));
        }
    }, [window.innerWidth]);

    useEffect(() => {
        gsap.set(elGroup('.word span'), {
            y: 100,
            position: 'relative',
            opacity: 0,
            transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
            display: 'inline-block'
        });
        let delay = 0.05;
        gsap.utils.toArray(elGroup('.word span')).forEach(function (e, index) {
            gsap.to(elGroup('.word span')[index], {
                scrollTrigger: {
                    trigger: el.current,
                    start: "top 70%",
                    scrub: false,
                },
                y: 0,
                ease: "expo.out",
                duration: 0.8,
                delay,
                opacity: 1
            });
            delay += 0.05;
        });
    }, []);

    return (
        <section ref={el} id={'sector_cards'} data-section-theme={'blue'}
                 className="block block--cards blue"
                 data-spy={'#sector_cards'}>
            <div className="block__content pt-4 pt-lg-8">
                <div className="container d-flex flex-column gap-5 gap-lg-6">
                    <div className="row">
                        <div className="col-lg-6 formue-animation d-flex flex-column gap-5">
                            <h2>Sectors</h2>
                            <p className="lead"><i><strong
                                dangerouslySetInnerHTML={{__html: staticValue.largestShare}}></strong></i><br/>
                                <span
                                    className="word"><span>– is </span></span> <span
                                    className="word"><span>responsible</span></span> <span
                                    className="word"><span>for the</span></span> <span
                                    className="word"><span>largest</span></span> <span
                                    className="word"><span>share</span></span> <span
                                    className="word"><span>({(100 * allIndustries[1].value / totalImpactValue).toFixed(0)} %) of the</span></span>
                                <span className="word"> <span>negative</span></span> <span
                                    className="word"><span> impact among</span></span> <span className="word"><span> 55 sectors.</span></span>
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="grid d-block d-lg-grid"
                                 style={{
                                     display: 'grid',
                                     gridTemplateColumns: 'repeat(11, 1fr)',
                                     gridTemplateRows: 'repeat(12, 1fr)',
                                     gap: 24
                                 }}
                                 data-duration="1"
                                 data-ease="elastic.out(0.5)"
                                 data-scale="1.4"
                                 data-max-rotation="20"
                                 data-spread="150"
                                 data-max-distance="700"
                            >
                                {allIndustries.map((item, index) => {
                                    let percent = (100 * item.value / totalImpactValue).toFixed(0);
                                    return <Card grid={item.grid} img={item.img} percent={percent} activeID={activeID}
                                                 setActiveID={setActiveID}
                                                 cardID={item.id}
                                                 key={item.id}
                                                 index={index}
                                                 name={item.name}
                                                 value={item.value} companies={item.companies}
                                                 negativeCalculation={negativeCalculation}
                                                 totalNegativeImpact={item.value}
                                                 type={'sector_cards'}
                                                 order={item.order}

                                    />
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Sectorsv2;
