import translations from '../data/lang.json'
import {gsap} from "gsap";


export function getNegativeImpactValue(index, language, investmentsSize) {

    return ((investmentsSize / 1000000) * index);
}

export function calculateInvestmentFootprint(updateInvestmentValue) {
    setTimeout(function () {
        let value = document.getElementById('invesmentValueVisible').value;
        if (value === '') {
            value = 5
        }
        updateInvestmentValue(value)
    }, 500)
}

export function addListenerMulti(element, eventNames, listener) {
    var events = eventNames.split(' ');
    for (var i = 0, iLen = events.length; i < iLen; i++) {
        element.addEventListener(events[i], listener, false);
    }
}

export function mouseMoveHandler(e, movableElements) {
    movableElements.forEach((movableElement) => {
        const shiftValue = movableElement.getAttribute('data-value');
        const moveX = (e.clientX * shiftValue) / 250;
        const moveY = (e.clientY * shiftValue) / 250;
        gsap.to(movableElement, {x: moveX, y: moveY, duration: 1})
    });
}

export function throttled(delay, fn) {
    let lastCall = 0;
    return function (...args) {
        const now = (new Date).getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return fn(...args);
    }
}

export function detectFontSize(characters) {
    if (characters == null) {
        return
    }
    let size = characters.replaceAll(' ', '').length

    let multi = 1;
    if (window.innerWidth < 768) {
        multi = 0.7
    }
    if (16 <= size) {
        return 50 * multi;
    }
    if (15 <= size) {
        return 65 * multi;
    }
    if (14 <= size) {
        return 75 * multi;
    }
    if (13 <= size) {
        return 80 * multi;
    }
    if (12 <= size) {
        return 85 * multi;
    }
    return 96 * multi;
}

// export function scrollToSection(id) {
//     let position = '#' + id
//     if (id === 'investing_return') {
//         position = document.getElementById(id).offsetTop - window.innerHeight / 2
//     }
//     gsap.set(window, {scrollTo: position, delay: 0});
// }
export function scrollToSection(id) {
    let clearID = id.replace('#', '');
    if (id === '#investing_return') {
        id = window.innerHeight
        clearID = 'investing_return'
    }
    if (id === '#negativeImpact') {
        id = window.innerHeight * 2.55
        clearID = 'negativeImpact'
    }
    if (id === '#take_action_title') {

        id = parseFloat(document.getElementById('take_action_title').getAttribute('data-distance')) - window.innerHeight / 4.5
    }
    gsap.set('.scrollspy span', {className: "=-active"});

    gsap.set('span[data-id="' + clearID + '"]', {className: "active"});

    gsap.set(window, {scrollTo: id, delay: 0});
}

export function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}

export function langSeparator(lang) {
    switch (lang) {
        case 'com':
            return 'en'
        case 'se':
            return 'sv'
        default:
            return 'no'
    }
}


