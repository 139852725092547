import React, {useEffect, useRef, useState} from "react";
import {gsap, Linear} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {mouseMoveHandler, throttled} from "../hooks/helperFunctions";
import {Settings} from "../store";

const TakeActionStep3 = () => {
    gsap.registerPlugin(ScrollTrigger);
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);

    useEffect(() => {
        gsap.set(elGroup('.move-element, .horizontal-dashed-line, .arrow, .tco2'), {
            opacity: 0
        });
        gsap.set(elGroup('.circle'), {
            scale: 0,
            rotate: 50
        });
        gsap.set(elGroup('.diagram'), {
            opacity: 0,
            y: '50vh'
        });

    }, []);

    useEffect(() => {

        const pathPrepare = (el) => {
            let lineLength = el.getTotalLength();
            el.style["strokeDasharray"] = lineLength
            el.style["stroke-dashoffset"] = lineLength
        }

        let pathLine1 = document.getElementById('colorline-1');
        let pathLine2 = document.getElementById('colorline-2');
        let pathLine3 = document.getElementById('colorline-3');

        pathPrepare(pathLine1);
        pathPrepare(pathLine2);
        pathPrepare(pathLine3);

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: () => {
                    return "top 50%"
                },
                end: () => {
                    return "bottom 100%"
                },
                invalidateOnRefresh: true,
                scrub: 0.7
            }
        })
        tl.to(elGroup('.diagram'), {opacity: 1, y: 0}, 'same1')
        tl.to(elGroup('.tco2'), {opacity: 1, ease: Linear.ease, duration: 1.9}, 'same1')
        tl.to(pathLine1, {strokeDashoffset: 0, ease: Linear.ease, duration: 1.9}, 'same1')
        tl.to(elGroup('.horizontal-dashed-line'), {opacity: 1, ease: Linear.ease, duration: 1.9})
        tl.to(pathLine2, {strokeDashoffset: 0, ease: Linear.ease, duration: 1.9})
        tl.to(elGroup('.arrow'), {opacity: 1, ease: Linear.ease, duration: 1.9}, 'same')
        tl.to(pathLine3, {strokeDashoffset: 0, ease: Linear.ease, duration: 1.9}, 'same')
    }, []);


    return (<section data-section-theme="purple" data-spy={'#ta3'} id={'ta3'} ref={el} className="block block--takeaction block--rounded-bottom">
        <div className="block__content">
            <div className="container">
              <div className="text-center mb-6 d-block d-lg-none">
                <h4 className="preheader mb-3">Take action - 03</h4>
              </div>
                <div className="row align-items-center justify-content-between flex-column-reverse flex-lg-row gap-6 gap-lg-0">
                    <div className="col-lg-5">
                        <div className="formue-animation d-flex flex-column gap-5">
                          <div>
                            <h4 className="preheader mb-3 d-none d-lg-block">Take action - 03</h4>
                            <h2>Set your <i>Paris Target</i> and monitor</h2>
                          </div>
                            <p>Having the complete picture of your wealth footprint and deciding your sustainability preferences enables you to set your Paris Target. Remember to monitor your progress.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center">
                        <div className="diagram position-relative">
                            <svg className="main-circle h-auto" width="600" height="600" viewBox="0 0 505 505" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="252.5" cy="252.5" r="252.5" fill="#C5A5D6" fillOpacity="0.2"/>
                            </svg>

                            <div className="position-absolute start-50 top-50 translate__middle">
                                <svg className=" ruler" width="181" height="323" viewBox="0 0 181 323" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path className="horizontal-dashed-line"
                                          d="M132.013 199.242C132.037 198.967 131.834 198.724 131.559 198.7L127.077 198.298C126.802 198.273 126.559 198.476 126.535 198.751C126.51 199.026 126.713 199.269 126.988 199.294L130.972 199.651L130.615 203.635C130.59 203.91 130.793 204.153 131.068 204.178C131.343 204.202 131.586 203.999 131.611 203.724L132.013 199.242ZM93.8595 212.331C97.8338 205.839 101.402 202.484 104.666 200.893C107.912 199.31 110.923 199.441 113.874 200.078C115.359 200.398 116.81 200.842 118.281 201.261C119.742 201.676 121.215 202.065 122.697 202.253C125.691 202.633 128.715 202.188 131.835 199.581L131.194 198.814C128.324 201.212 125.592 201.612 122.822 201.261C121.422 201.084 120.011 200.713 118.555 200.299C117.11 199.887 115.612 199.429 114.085 199.1C111.013 198.438 107.75 198.277 104.228 199.994C100.724 201.702 97.0305 205.236 93.0066 211.809L93.8595 212.331Z"
                                          fill="#301B59"/>
                                    <line x1="144.586" y1="33.3481" x2="156.237" y2="33.3481" stroke="#301B59"/>
                                    <line x1="144.586" y1="41.8535" x2="167.889" y2="41.8535" stroke="#301B59"/>
                                    <line x1="144.586" y1="50.3584" x2="156.237" y2="50.3584" stroke="#301B59"/>
                                    <line x1="144.586" y1="58.8613" x2="156.237" y2="58.8613" stroke="#301B59"/>
                                    <line x1="144.586" y1="67.3662" x2="156.237" y2="67.3662" stroke="#301B59"/>
                                    <line x1="144.586" y1="75.8716" x2="156.237" y2="75.8716" stroke="#301B59"/>
                                    <line x1="144.586" y1="84.377" x2="156.237" y2="84.377" stroke="#301B59"/>
                                    <line x1="144.586" y1="92.8794" x2="156.237" y2="92.8794" stroke="#301B59"/>
                                    <line x1="144.586" y1="101.385" x2="156.237" y2="101.385" stroke="#301B59"/>
                                    <line x1="144.586" y1="109.888" x2="156.237" y2="109.888" stroke="#301B59"/>
                                    <line x1="144.586" y1="118.393" x2="156.237" y2="118.393" stroke="#301B59"/>
                                    <line x1="144.586" y1="126.897" x2="167.889" y2="126.897" stroke="#301B59"/>
                                    <line x1="144.586" y1="135.4" x2="156.237" y2="135.4" stroke="#301B59"/>
                                    <line x1="144.586" y1="143.905" x2="156.237" y2="143.905" stroke="#301B59"/>
                                    <line x1="144.586" y1="152.411" x2="156.237" y2="152.411" stroke="#301B59"/>
                                    <line x1="144.586" y1="160.916" x2="156.237" y2="160.916" stroke="#301B59"/>
                                    <line x1="144.586" y1="169.421" x2="156.237" y2="169.421" stroke="#301B59"/>
                                    <line x1="144.586" y1="177.924" x2="156.237" y2="177.924" stroke="#301B59"/>
                                    <line x1="144.586" y1="186.429" x2="156.237" y2="186.429" stroke="#301B59"/>
                                    <line x1="144.586" y1="194.935" x2="156.237" y2="194.935" stroke="#301B59"/>
                                    <line x1="144.586" y1="203.439" x2="156.237" y2="203.439" stroke="#301B59"/>
                                    <line x1="144.586" y1="211.942" x2="167.889" y2="211.942" stroke="#301B59"/>
                                    <line x1="144.586" y1="220.447" x2="156.237" y2="220.447" stroke="#301B59"/>
                                    <line x1="144.586" y1="228.95" x2="156.237" y2="228.95" stroke="#301B59"/>
                                    <line x1="144.586" y1="237.455" x2="156.237" y2="237.455" stroke="#301B59"/>
                                    <line x1="144.586" y1="245.96" x2="156.237" y2="245.96" stroke="#301B59"/>
                                    <line x1="144.586" y1="254.463" x2="156.237" y2="254.463" stroke="#301B59"/>
                                    <line x1="144.586" y1="262.969" x2="156.237" y2="262.969" stroke="#301B59"/>
                                    <line x1="144.586" y1="271.474" x2="156.237" y2="271.474" stroke="#301B59"/>
                                    <line x1="144.586" y1="279.979" x2="156.237" y2="279.979" stroke="#301B59"/>
                                    <line x1="144.586" y1="288.484" x2="156.237" y2="288.484" stroke="#301B59"/>
                                    <line x1="144.586" y1="296.986" x2="167.889" y2="296.986" stroke="#301B59"/>
                                    <line x1="144.586" y1="305.492" x2="156.237" y2="305.492" stroke="#301B59"/>
                                    <line x1="144.586" y1="313.997" x2="156.237" y2="313.997" stroke="#301B59"/>
                                    <line id="colorline-1" x1="145.5" y1="321.008" x2="145.5" y2="194.991"
                                          stroke="#437047" strokeWidth="5"/>
                                    <line id="colorline-2" x1="145.5" y1="195" x2="145.5" y2="101" stroke="#F2C94C"
                                          strokeWidth="5"/>
                                    <line id="colorline-3" x1="145.5" y1="101" x2="145.5" y2="49" stroke="#F2637B"
                                          strokeWidth="5"/>
                                    <rect x="142.68" y="26.3438" width="37.3231" height="295.657" rx="4"
                                          stroke="#301B59" strokeWidth="2"/>
                                </svg>


                                <h3 className="position-absolute small horizontal-dashed-line paris-target text-end fst-italic">Paris<br/>
                                    agreement</h3>
                                <div className="arrow position-absolute d-flex align-items-start">
                                    <h3 className="small d-inline-block me-3 fst-italic">You are here</h3>
                                    <svg width="38" height="23" viewBox="0 0 38 23" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M37.6202 19.1843C37.8221 18.9959 37.8332 18.6795 37.6448 18.4776L34.5757 15.1866C34.3874 14.9847 34.071 14.9737 33.869 15.162C33.6671 15.3503 33.656 15.6667 33.8444 15.8687L36.5725 18.794L33.6472 21.5221C33.4453 21.7104 33.4342 22.0268 33.6226 22.2288C33.8109 22.4307 34.1273 22.4417 34.3293 22.2534L37.6202 19.1843ZM0.474448 3.46278C7.767 1.28185 12.6643 1.16518 16.1564 2.15907C19.6297 3.14761 21.7844 5.25523 23.5568 7.69891C24.4488 8.92873 25.2329 10.2276 26.0487 11.5214C26.8586 12.8059 27.696 14.0791 28.6737 15.2078C30.65 17.4893 33.1987 19.1766 37.2618 19.3183L37.2966 18.3189C33.5588 18.1886 31.2575 16.6632 29.4296 14.5531C28.5053 13.486 27.702 12.2686 26.8946 10.988C26.093 9.71674 25.2832 8.37595 24.3663 7.11179C22.5209 4.5675 20.1985 2.26979 16.4301 1.19726C12.6805 0.130076 7.57143 0.296581 0.187924 2.50471L0.474448 3.46278Z"
                                            fill="#CCDDEF"/>
                                    </svg>
                                </div>
                                <div className="tco2 position-absolute">
                                    <h3 className="small"><i>Tons of tCO2</i></h3>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default TakeActionStep3;
