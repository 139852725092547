import React, {useRef, useEffect, useState} from "react";
import gsap from "gsap"
import Modal from "react-bootstrap/Modal";
import {Settings} from "../store";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Calendly from "./Calendly";
import TagManager from 'react-gtm-module';

const Widget = (props) => {
    const {
        showContactModal,
        hideContactModal,
        isContactOpen,
        showCalendlyModal,
        hideCalendlyModal,
        isCalendlyOpen
    } = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [formID, setFormID] = useState(6)
    const [contactPreloader, setContactPreloader] = useState('')
    const [thankYouMessage, setThankYouMessage] = useState(false)
    const el = useRef(null);

    useEffect(() => {
        switch (language) {
            case 'no':
                setFormID(6);
                break
            case 'se':
                setFormID(63);
                break
            case 'com':
                setFormID(68);
                break
            default:
                setFormID(6);
        }

    }, [language]);
    let iteration = 0;
    useEffect(() => {

        if (isContactOpen) {
            setThankYouMessage(false)
            let iFrame = document.getElementById('i_frame');
            iFrame.onload = function () {
                iteration++
                setContactPreloader('d-none')
                if (iteration === 2) {

                    TagManager.dataLayer({
                        dataLayer: {
                            userProject: 'Impact',
                            event: 'formSubmission',
                            formType: 'Popup',
                            formName:'Contact form',
                            formCategory:'Contact'
                        }
                    });

                    setThankYouMessage(true)
                }
            };

        } else {
            //wait for modal to close then show preloader
            setTimeout(() => {
                setContactPreloader('d-block')
            }, 500)

        }
    }, [isContactOpen]);


    useEffect(() => {
        if (thankYouMessage) {
            iteration = 0;
        }
    }, [thankYouMessage]);


    return (<>

            <div className="widget purple">
                <div><p>Let's get started</p></div>
                <button type="button" className="btn btn-primary" onClick={() => {
                    TagManager.dataLayer({
                        dataLayer: {
                            userProject: 'Impact',
                            event: 'CTAevent',
                            CTAaction: 'Contact us - sticky'
                        }
                    });
                    showContactModal();
                    hideCalendlyModal();
                }}>Contact us
                </button>

            </div>
            <Modal className={'modal--contact'} size="md" dialogClassName={'modal-fullscreen-md-down'}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isContactOpen} onHide={hideContactModal}>

                <div className="modal-header d-flex justify-cotnent-between">

                    <button type="button" className="btn btn-link btn--forward" onClick={() => {
                        TagManager.dataLayer({
                            dataLayer: {
                                userProject: 'Impact',
                                event: 'CTAevent',
                                CTAaction: 'Calendly - inside Contact us popup'
                            }
                        });
                        hideContactModal();
                        showCalendlyModal();

                    }}>Schedule a meeting
                    </button>

                    <button onClick={hideContactModal} type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <h3 className="mb-3">{thankYouMessage ? 'Thank you.' : 'Contact us'}</h3>
                    <div className="position-relative">
                        <iframe className={thankYouMessage && 'd-none'}
                                style={{opacity: contactPreloader === 'd-none' ? 1 : 0}} id="i_frame"
                                src={'https://formue.tfaforms.net/rest/forms/view/' + formID}
                                height={window.innerWidth < 768 ? 1400 : 1050} width="600"
                                frameBorder="0"></iframe>
                        <div
                            className={contactPreloader + " position-absolute start-0 end-0 top-0 bottom-0 d-flex align-items-start justify-content-center mt-5 pt-5"}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Calendly isCalendlyOpen={isCalendlyOpen} hideCalendlyModal={hideCalendlyModal}
                      showContactModal={showContactModal}/>
        </>
    )
}

export default Widget;
