import React, {useEffect, useRef, useState} from "react";
import {FrameGrid} from "@egjs/grid";
import {Settings} from "../store";
import translations from '../data/lang.json';
import SectorItem from './SectorItem';
import {gsap} from "gsap";

const SectorsTab = (props) => {
    const {
        activeSDG,
        showModalDetails,
        setActiveSDGSector,
        totalSDGImpactValue
    } = props;
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    return (<>
            <div ref={el} className="grid grid--tabs d-block d-lg-grid"
                 style={{
                     gridTemplateColumns: 'repeat(5, 1fr)',
                     gridTemplateRows: 'repeat(4, 1fr)',
                     gap: 10,
                     height: 500
                 }}>
                {activeSDG.sectors.map((item, index) => <SectorItem grid={item.grid} key={item.totalNegativeImpact} index={index}
                                                                    setActiveSDGSector={setActiveSDGSector}
                                                                    showModalDetails={showModalDetails} item={item}
                                                                    activeSDG={activeSDG}
                                                                    totalSDGImpactValue={totalSDGImpactValue}
                                                                    language={language}/>)}
            </div>
        </>
    )
}

export default SectorsTab;
