import React, {useEffect, useRef, useState} from "react";
import {SmoothCorners} from 'react-smooth-corners'
import sdgData from "../data/sdg-data.json";

const SDG = (props) => {
    const {
        item,
        setActiveSDG,
        setActiveSDGCard,
        showModal,
        totalSDGImpactValue,
        grid,
        order
    } = props;
    const id = sdgData[item].id;
    const color = sdgData[item].color;
    const name = sdgData[item].name;
    const el = useRef(null);
    const percent = (sdgData[item].totalSectorImpact * 100) / totalSDGImpactValue
    const [lockIcon, setLockIcon] = useState(false)
    // useEffect(() => {
    //     if (!lockIcon) {
    //         window.bodymovin.loadAnimation({
    //             wrapper: el.current,
    //             animType: 'svg',
    //             loop: true,
    //             path: '../media/help.json'
    //         });
    //         setLockIcon(true)
    //     }
    // }, []);


    return (
        <div onClick={() => {
            setActiveSDG(sdgData['SDG' + id]);
            setActiveSDGCard(id);
            showModal();
        }}
             data-sdg={id}
             className={'card grid__item order-' + order}
             style={{gridArea: grid, background: color, '--medium': 'auto'}}>

            <div className={'grid__item__inner'}>
                <div className="d-block d-lg-none">
                    <span className="label">{id}. {name}</span>
                </div>
                <div className="h-100 d-none d-lg-block">
                    <img className="opacity-100" src={'../media/SDGs/' + id + '.svg'} alt=""/>
                </div>
                <h3 className="percentage">{percent.toFixed(0)}</h3>
                <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path
                    d="M7.15 21.1q-.375-.375-.375-.888 0-.512.375-.887L14.475 12l-7.35-7.35q-.35-.35-.35-.875t.375-.9q.375-.375.888-.375.512 0 .887.375l8.4 8.425q.15.15.213.325.062.175.062.375t-.062.375q-.063.175-.213.325L8.9 21.125q-.35.35-.862.35-.513 0-.888-.375Z"/></svg></span>
            </div>
        </div>
    );
}

export default SDG;
