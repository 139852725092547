import React, {useEffect, useState, useRef} from "react";
import {gsap,} from "gsap";
import imagesLoaded from "imagesloaded";
import {ProgressBar} from 'react-bootstrap';
import CustomEase from "gsap/CustomEase";

const LoadingScreenv2 = (props) => {
    gsap.registerPlugin(CustomEase);
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const {setIsLoaded} = props;
    const [percentage, setPercentage] = useState(0);
    const [imagesReady, setImagesReady] = useState(false);

    useEffect(() => {
        CustomEase.create("custom", "M0,0 C0,0 0.303,-0.008 0.54,0.228 0.655,0.343 0.676,0.571 0.756,0.718 0.856,0.904 1,1 1,1")
        gsap.set(elGroup('svg'), {opacity: 0})
    }, []);


    useEffect(() => {
        let i = 0;
        let interval = setInterval(increment, 40);
        const stop = 100;
        gsap.to(elGroup('svg'), {opacity: 1, duration: 4, delay: 1})

        function increment() {
            i = i + 2;
            if (i === stop) {
                clearTimeout(interval)
                imagesLoaded(document.body, function () {
                    setIsLoaded(true);
                    gsap.to(el.current, {
                        height: 0,
                        duration: 1,
                        ease: "custom"
                    })
                });
            }
            setPercentage(i);
        }
    }, []);


    return (
        <div ref={el} className="loading-screen wrap overflow-hidden">
            <div className="vh-100 d-flex w-100 align-items-center position-relative">
                <div className="container-fluid p-0 text-center">
                    <svg width={'30vw'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 925 160">
                        <path
                            d="m783.232 86.247.068-79.729h-24.638l-.068 79.729a46.149 46.149 0 1 1-92.3 0h.006V6.518h-24.633l-.006 79.729c0 42.88 35.732 71.185 70.786 71.2 34.412.006 70.853-27.647 70.785-71.2ZM197.782 2.558a77.4 77.4 0 1 0 77.4 77.4 77.486 77.486 0 0 0-77.4-77.4Zm52.76 77.4a52.76 52.76 0 1 1-52.76-52.76 52.82 52.82 0 0 1 52.76 52.758ZM424.335 75.9a69.46 69.46 0 0 0-69.381-69.382v24.638A44.794 44.794 0 0 1 399.7 75.9Zm.165 78.654v-24.639a44.794 44.794 0 0 1-44.743-44.743h-24.644a69.46 69.46 0 0 0 69.387 69.382Zm41.045-69.334h24.638v69.127h-24.638zm112.79 0h24.638v69.127h-24.638zM305.439 6.518h24.638v147.829h-24.638zM6.365 154.347h25.442V31.339l75.826-.183V6.518H6.365v147.829z"
                            className="cls-1"></path>
                        <path
                            d="M55.344 68.225h28.753v24.638H55.344zm810.981 0h28.753v24.638h-28.753zM534.099 97.149l77.054-77.054-17.421-17.422-59.633 59.632-59.632-59.632-17.422 17.422 77.054 77.054z"
                            className="cls-1"></path>
                        <path
                            d="M818.171 154.347h100.464v-24.638l-75.826-.183V31.339l75.826-.183V6.518H818.171v147.829z"
                            className="cls-1"></path>
                    </svg>
                </div>
                <div className="progressBar position-absolute text-center" style={{width: 300}}>
                    <h3>{percentage}</h3>
                    <ProgressBar now={percentage}/>
                </div>
            </div>
        </div>
    )
}

export default LoadingScreenv2;
