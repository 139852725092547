import './App.css';
import Home from "./components/Home";
import {useLanguageDetector} from './services/useLanguageDetector';
import './App.scss';
import {gsap} from "gsap";
import { ParallaxProvider } from 'react-scroll-parallax';
import {usePageTracking} from "./services/usePageTracking";

const App = () => {
    useLanguageDetector();
    usePageTracking();
    gsap.config({
        nullTargetWarn: false
    })
    return (
        <ParallaxProvider>
            <Home/>
        </ParallaxProvider>
    );
}

export default App;