import React, {useEffect, useRef} from "react"
import InvestingReturn from "./InvestingReturn";
import Sectorsv2 from "./Sectorsv2";
import Continents from "./Continents";
import SDGs from "./SDGs";
import TakeAction from "./TakeAction";
import ScrollspyNav from "./ScrollspyNav";
import Footer from "./Footer";
import {Settings} from "../store";
import {gsap} from "gsap";
import {hasClass, langSeparator} from "../hooks/helperFunctions";

const Main = (props) => {

    const {
        totalNegativeImpact2020,
        negativeCalculation,
        investmentValue,
        isLoaded,
        showContactModal,
        hideMain
    } = props;
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);

    useEffect(() => {
        elGroup(".formue-animation").forEach(function (e) {
            let delay = 0;
            let duration = 1.2;
            if (e.getAttribute('data-delay') !== null) {
                delay = e.getAttribute('data-delay')
            }

            if (e.getAttribute('data-duration') !== null) {
                duration = e.getAttribute('data-duration')
            }
            gsap.from(e, {
                duration: duration,
                opacity: 0,
                y: 20,
                delay: delay,
                scrollTrigger: {
                    trigger: e,
                    start: "top 80%"
                }
            });
        });
    }, [])

    useEffect(() => {
        elGroup("section").forEach(function (e) {
            if (hasClass(e, 'block')) {
                gsap.to(e, {
                    scrollTrigger: {
                        trigger: e,
                        start: "top 5px",
                        end: "bottom",
                        toggleClass: 'in-viewport'
                    }
                });
            }
            let theme = e.getAttribute('data-section-theme');
            if (e.getAttribute('data-section-theme') == null) {
                return
            }
            gsap.timeline({
                scrollTrigger: {
                    trigger: e,
                    start: "top 50%",
                    end: "bottom 50%",
                    onEnterBack: () =>
                        gsap.set("body", {attr: {["data-theme"]: theme}}),
                    onEnter: () =>
                        gsap.set("body", {attr: {["data-theme"]: theme}}),
                }
            });
        });
    }, [])

    useEffect(() => {
        if (!hideMain) {
            gsap.set('main', {
                visibility: 'visible', height: 'auto', overflow: 'initial'
            })

            gsap.to(window, 0, {
                scrollTo: document.getElementById('banner').clientHeight,
                ease: "none",
                duration: 1,
                delay: 0,
                onComplete: () => {
                    const el = document.querySelectorAll('body, html, .outer-wrapper');
                    el.forEach(box => {
                        box.classList.remove('no-scroll');
                    });
                }
            });
        }
    }, [hideMain]);


    return (<main ref={el} id="main" className="main">
        <InvestingReturn totalNegativeImpact2020={totalNegativeImpact2020} negativeCalculation={negativeCalculation}
                         investmentValue={investmentValue}/>
        <section className="sectors block block--rounded-top">
            <header className="section-header">
                <div className="section-header__inner">
                    <h4 className="currency">
                        <span>Your impact:</span> - {negativeCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})}
                    </h4>
                </div>
            </header>
            <div className="overflow-hidden">
              <Sectorsv2 negativeCalculation={negativeCalculation} investmentValue={investmentValue}/>
              <Continents investmentValue={investmentValue} negativeCalculation={negativeCalculation}/>
              <SDGs negativeCalculation={negativeCalculation} investmentValue={investmentValue}/>
            </div>
        </section>
        <TakeAction/>
        <ScrollspyNav isLoaded={isLoaded}/>
        <Footer showContactModal={showContactModal}/>
    </main>)
}

export default Main;
