import React, {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {Settings} from "../store";

const TakeActionTitle = () => {
    gsap.registerPlugin(ScrollTrigger);
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);

    useEffect(() => {

        gsap.set(elGroup('.heading-animation'), {
            x: window.innerWidth
        })
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: "top 60%",
                end: "bottom",
                scrub: true,
                invalidateOnRefresh: true
            }
        });
        tl
            .to(elGroup('.heading-animation'), {
                x: -window.innerWidth
            })

    }, []);
    return (
        <section id="take_action_title" data-section-theme="purple" ref={el} className="block block--invert block--rolling-header block--takeaction block--sticky blue">
          <div className="overflow-hidden">
              <div className="block__content h-auto d-flex align-items-center">
                  <div className="container">
                      <div className="row d-flex justify-content-center text-center">
                          <div className="col-lg-12">
                              <h2 className="block__heading block__heading--large"><span>Take action. </span><span>Take action. </span><span>Take action. </span><span>Take action. </span><span>Take action. </span><span>Take action. </span>
                              </h2>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </section>

    )
}

export default TakeActionTitle;
