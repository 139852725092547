import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Settings} from '../store';
import {InlineWidget} from 'react-calendly';

const Calendly = (props) => {
    const {showContactModal, showCalendlyModal, hideCalendlyModal, isCalendlyOpen} = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [calendlyLink, setCalendlyLink] = useState('https://calendly.com/formue-no/15min?hide_gdpr_banner=1&background_color=F5F3F0&text_color=2F3A71&primary_color=385795');
    const el = useRef(null);

    useEffect(() => {
        if (language === 'se') {
            setCalendlyLink('https://calendly.com/formue-se/15?month=2023-07&hide_gdpr_banner=1&background_color=F5F3F0&text_color=2F3A71&primary_color=385795');
        } else {
            setCalendlyLink('https://calendly.com/formue-no/15min?hide_gdpr_banner=1&background_color=F5F3F0&text_color=2F3A71&primary_color=385795');
        }
    }, [language]);


    return (<>
        <Modal className={'modal--calendly'} size="sm" dialogClassName={'modal-fullscreen-md-down'}
               aria-labelledby="contained-modal-title-vcenter"
               centered show={isCalendlyOpen} onHide={hideCalendlyModal}>
            <div className="modal-header d-flex justify-cotnent-between">
                <button type="button" className="btn btn-link btn--back" onClick={() => {
                    hideCalendlyModal();
                    showContactModal();
                }}>Go Back
                </button>

                <button onClick={hideCalendlyModal} type="button" className="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <InlineWidget
                        url={calendlyLink}/>
            </div>

            <div className="modal-footer">
                {/*<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary">Save changes</button>*/}
            </div>
        </Modal>
    </>);
};

export default Calendly;
