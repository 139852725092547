import React from 'react'
import { Settings } from './settings'

const providers = [
  <Settings.Provider />,
]

const Store = ({ children: initial }) =>
  providers.reduce(
    (children, parent) => React.cloneElement(parent, { children }),
    initial
  )

export { Store, Settings }