import translations from "../data/lang.json";
import React, {useState} from "react";
import {langSeparator} from "../hooks/helperFunctions";

const ContinentItem = (props) => {
    const {item, activeSDG, language, negativeImpactByType, showModalDetails, setActiveSDGSector,grid} = props;
    const [activeItem, setActiveItem] = useState(false)
    const totalImpactIN2020 = item.totalNegativeImpact;
    const totalImpactIn2020Procent = (totalImpactIN2020 * 100) / activeSDG.totalSectorImpact;
    return (<div  style={{gridArea: grid}} onClick={(e) => {
        document.body.classList.toggle('selected')
        e.currentTarget.classList.add('active')
        // setActiveItem(true)
        showModalDetails()
        setActiveSDGSector(item);

    }} className={"sectors__item"}
                 data-grid-column={item.columns}>
        <div className="content">
            <div className="overlay-fill" style={{width: totalImpactIn2020Procent + '%'}}></div>
            <div className="base-info">
              <span className="label">
                {item.name}
              </span>
                <h3 className="percentage">
                    {totalImpactIn2020Procent.toFixed(0)}
                </h3>

            </div>
            <div className="details">
                All sectors in
                2022: {(totalImpactIN2020 * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency}<br/>
                Based on investment :
                {/*- {(negativeImpactByType['SDG' + activeSDG.id].value * totalImpactIn2020Procent / 100).toFixed(0)} {translations[language].general.currency}<br/>*/}
                Companies : {item.companies}
            </div>
        </div>
    </div>)
}

export default ContinentItem;
