import React, {useEffect, useRef, useState} from "react";
import SDG from "./SDG";
import translations from "../data/lang.json";
import Modal from "react-bootstrap/Modal";
import {Settings} from "../store";
import SectorsTab from "./SectorsTab";
import ContinentsTab from "./ContinentsTab";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {gsap} from "gsap";
import {Navigation, Pagination, Mousewheel, Keyboard} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import sdgData from "../data/sdg-data.json";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {langSeparator} from "../hooks/helperFunctions";

const SDGModal = (props) => {
    const {
        isOpen,
        hideModal,
        activeSDG,
        SDGCards,
        negativeCalculation,
        setActiveSDG,
        showModalDetails,
        setActiveSDGSector,
        totalSDGImpactValue
    } = props;

    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [activeTab, setActiveTab] = useState('sectors');
    const [overlayColor, setOverlayColor] = useState('#F2FAF0');
    const [swiper, setSwiper] = useState(null);
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);



    useEffect(() => {
        setOverlayColor(activeSDG.overlayColor)
    }, [activeSDG]);


    const renderTooltip = (text, props) => (<Tooltip {...props}>
        {text}
    </Tooltip>);

    return (<Modal size="xl"
                   fullscreen={true}
                   scrollable={true}
                   dialogClassName={'modal-fullscreen-md-down'}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpen} onHide={hideModal}
                   className={'modal--sdg'}
                   onExit={() => document.body.classList.remove('selected')}
        >

            <div className="overlay-background" style={{background: overlayColor}}></div>
            <div className="modal-header">
                <button onClick={hideModal} type="button" className="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
            </div>
            <div className="modal-body px-0">

                <div className="swiper-controls">

                    <div className="sdg-small-cards"></div>


                </div>

                <div className="swiper-buttons">
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>

                <Swiper
                    initialSlide={activeSDG.slideId}
                    cssMode={false}
                    slidesPerView={1}
                    loop={true}
                    autoHeight={false}
                    allowTouchMove={false}
                    mousewheel={false}
                    keyboard={true}
                    centeredSlides={true}
                    navigation={{
                        nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',
                    }}
                    onSwiper={setSwiper}
                    pagination={{
                        dynamicBullets: true,
                        dynamicMainBullets: 1,
                        clickable: true,
                        el: '.sdg-small-cards',
                        renderBullet: function (index, className) {
                            switch (index) {
                                case 0:
                                    index = 3
                                    break
                                case 1:
                                    index = 6
                                    break
                                case 2:
                                    index = 7
                                    break
                                case 3:
                                    index = 11
                                    break
                                case 4:
                                    index = 13
                                    break
                                case 5:
                                    index = 14
                                    break
                                case 6:
                                    index = 15
                                    break
                            }
                            let url = 'media/SDGs/' + index + '.svg';
                            return '<span class="' + className + '"><img style="background:' + sdgData['SDG' + index].color + ' " src="' + url + '" alt="sdg" /></span>'
                        }
                    }}

                    modules={[Pagination, Navigation, Mousewheel, Keyboard]}
                    onSlideChange={(s) => {
                        let id = document.querySelectorAll('.swiper-slide:nth-child(' + parseFloat(s.activeIndex + 1) + ') [data-sdg]')[0].getAttribute('data-sdg')
                        setActiveSDG(sdgData['SDG' + id])
                    }}>
                    {SDGCards.map((item) => {
                        const SDG = sdgData[item];
                        const percent = ((SDG.totalSectorImpact * 100) / totalSDGImpactValue);
                        const negativeValueForCurrentItem = (negativeCalculation * percent) / 100;

                        return <SwiperSlide className="" key={SDG.id}>
                            <div ref={el}
                                 className={"sdg sdg--" + SDG.id + " container details d-flex flex-column gap-3 gap-lg-6"}
                                 data-sdg={SDG.id}>

                                <div className="row text-center justify-content-center">
                                    <div className="col-lg-10 d-flex flex-column gap-4 align-items-center">
                                        <h2>{SDG.id}. {SDG.name}</h2>
                                        <p className="lead">{SDG.description}</p>
                                    </div>
                                </div>

                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className="box d-flex flex-column gap-3 gap-lg-4"
                                             style={{background: SDG.boxColor}}>
                                            <h3>Impact
                                                ({percent.toFixed(0)} %)
                                            </h3>
                                            <div>
                                                <p> - {(negativeValueForCurrentItem).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} of
                                                    -{negativeCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</p>

                                                <p>Total
                                                    impact: {(SDG.totalSectorImpact * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency}</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="box d-flex flex-column gap-3 gap-lg-4"
                                             style={{background: SDG.boxColor}}>
                                            <h3>Drivers</h3>

                                            <div className="d-flex flex-row flex-wrap gap-2 drivers">
                                                {SDG.drivers.map(item => {
                                                    if (item.tooltip !== '') {
                                                        return <OverlayTrigger key={item.name}
                                                                               overlay={<Tooltip
                                                                                   className="tooltip-driver">{item.tooltip}</Tooltip>}><span
                                                            style={{background: item.color}}
                                                            className={'drivers__item drivers__item--tooltip'}>{item.name}
                                                            <svg className="bi bi-info-circle"
                                                                 xmlns="http://www.w3.org/2000/svg" width="16"
                                                                 height="16"
                                                                 fill="currentColor"
                                                                 viewBox="0 0 16 16">
                                                        <path
                                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path
                                                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                      </svg>
                                                      </span></OverlayTrigger>
                                                    } else {
                                                        return <span key={item.name}
                                                                     style={{background: item.color}}
                                                                     className="drivers__item">{item.name}
                                                            <svg className="bi bi-info-circle"
                                                                 xmlns="http://www.w3.org/2000/svg" width="16"
                                                                 height="16"
                                                                 fill="currentColor"
                                                                 viewBox="0 0 16 16">
                                                        <path
                                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path
                                                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                      </svg>
                                                      </span>
                                                    }

                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" ref={el}>
                                    <div className="col-lg-12">
                                        <Tabs
                                            defaultActiveKey="sectors"
                                            id="nav-tab"
                                            activeKey={activeTab}
                                            transition={true}
                                            onSelect={(k) => {
                                                setActiveTab(k)
                                                document.body.classList.remove('selected')
                                            }}

                                        >
                                            <Tab eventKey="sectors" title="Sectors">
                                                <SectorsTab setActiveSDGSector={setActiveSDGSector}
                                                            showModalDetails={showModalDetails} className={'sectors'}
                                                            item={item} activeTab={activeTab}
                                                            totalSDGImpactValue={totalSDGImpactValue}
                                                    // negativeImpactByType={negativeImpactByType}
                                                            activeSDG={SDG}/>
                                            </Tab>
                                            <Tab eventKey="continents" className={'continents'} title="Continents">
                                                <ContinentsTab setActiveSDGSector={setActiveSDGSector}
                                                               showModalDetails={showModalDetails} activeTab={activeTab}
                                                               activeSDG={activeSDG}
                                                               totalSDGImpactValue={totalSDGImpactValue}
                                                />
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    })}

                    {/*</div>*/}

                </Swiper>

            </div>

        </Modal>

    )
}

export default SDGModal;
