import React, {useRef, useEffect} from "react";
import TakeActionTitle from "./TakeActionTitle";
import TakeActionStep1 from "./TakeActionStep1";
import TakeActionStep2 from "./TakeActionStep2";
import TakeActionStep3 from "./TakeActionStep3";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const TakeAction = () => {
    gsap.registerPlugin(ScrollTrigger);
    const el = useRef(null);
    useEffect(() => {
        gsap.to('body', {
            scrollTrigger: {
                trigger: el.current,
                start: "top",
                end: "bottom 100%",
                onEnter: () => {
                    document.body.classList.add('widget-is-sticky')
                },
                onLeave: () => {
                    document.body.classList.remove('widget-is-sticky')
                },
                onEnterBack: () => {
                    document.body.classList.add('widget-is-sticky')
                },
                onLeaveBack: () => {
                    document.body.classList.remove('widget-is-sticky')
                }
            },

        });
    }, []);

    return (
        <section ref={el} className="takeAction block">

            <TakeActionTitle/>
            <TakeActionStep1/>
            <TakeActionStep2/>
            <TakeActionStep3/>

        </section>
    )
}

export default TakeAction;
