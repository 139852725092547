import React, {useEffect, useState} from "react";
import {gsap} from "gsap";
import {Settings} from '../store';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import translations from '../data/lang.json';
import Banner from "./Banner";
import LoadingScreenv2 from "./LoadingScreenv2";
import Header from "./Header";
import {hasClass, langSeparator} from "../hooks/helperFunctions"
import BetaBar from "./BetaBar";
import Widget from "./Widget";
import Main from "./Main";

const Home = () => {
    gsap.registerPlugin(ScrollTrigger);

    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const [isLoaded, setIsLoaded] = useState(null);
    const [investmentValue, setInvestmentValue] = useState(0);
    const [negativeCalculation, setNegativeCalculation] = useState((investmentValue / 1000000) * 5573)
    const [totalNegativeImpact2020, setTotalNegativeImpact2020] = useState('')
    const [hideMain, setHideMain] = useState(true);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

    const showContactModal = () => {
        setIsContactOpen(true);
    };
    const hideContactModal = () => {
        setIsContactOpen(false);
    };
    const showCalendlyModal = () => {
        setIsCalendlyOpen(true);
    };
    const hideCalendlyModal = () => {
        setIsCalendlyOpen(false);
    };
    const updateInvestmentValue = e => {
        if (e.floatValue == undefined) {
            setInvestmentValue(0)
        } else {
            setInvestmentValue(e.floatValue)
        }
    };

    useEffect(() => {
        setNegativeCalculation((investmentValue / 1000000) * 5573)
    }, [investmentValue]);

    useEffect(() => {
        setTotalNegativeImpact2020((401785793893 * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0}) + ' ' + translations[language].general.billions + ' ' + translations[language].general.currency)
    }, [language]);

    useEffect(() => {
        if (isLoaded) {
            //needs to be 1200ms because of Loader screen
            setTimeout(() => {
                document.body.classList.remove('is-loading')
                document.body.classList.add('loaded')
            }, 1200)
            document.body.classList.add('lang-' + language)
        }
    }, [isLoaded]);


    useEffect(() => {
        gsap.utils.toArray(".formue-animation").forEach(function (e) {
            let delay = 0;
            let duration = 1.2;
            if (e.getAttribute('data-delay') !== null) {
                delay = e.getAttribute('data-delay')
            }

            if (e.getAttribute('data-duration') !== null) {
                duration = e.getAttribute('data-duration')
            }
            gsap.from(e, {
                duration: duration,
                opacity: 0,
                y: 20,
                delay: delay,
                scrollTrigger: {
                    trigger: e,
                    start: "top 80%"
                }
            });
        });
    }, [])

    useEffect(() => {
        gsap.utils.toArray("section").forEach(function (e) {
            if (hasClass(e, 'block')) {
                gsap.to(e, {
                    scrollTrigger: {
                        trigger: e,
                        start: "top 5px",
                        end: "bottom",
                        toggleClass: 'in-viewport'
                    }
                });
            }
            let theme = e.getAttribute('data-section-theme');
            if (e.getAttribute('data-section-theme') == null) {
                return
            }
            gsap.timeline({
                scrollTrigger: {
                    trigger: e,
                    start: "top 50%",
                    end: "bottom 100%",
                    onEnterBack: () =>
                        gsap.set("body", {attr: {["data-theme"]: theme}}),
                    onEnter: () =>
                        gsap.set("body", {attr: {["data-theme"]: theme}}),
                }
            });
        });
    }, [])

    return (<>
        <LoadingScreenv2 setIsLoaded={setIsLoaded}/>
        <div style={{visibility: isLoaded ? 'visible' : 'hidden'}} className="outer-wrapper">
            <BetaBar/>
            <Header/>
            <Banner hideMain={hideMain} setHideMain={setHideMain} investmentValue={investmentValue} isLoaded={isLoaded}
                    updateInvestmentValue={updateInvestmentValue}/>
            {!hideMain && <Main hideMain={hideMain} totalNegativeImpact2020={totalNegativeImpact2020}
                                negativeCalculation={negativeCalculation}
                                investmentValue={investmentValue}
                                isLoaded={isLoaded}
                                showContactModal={showContactModal}/>}

            <Widget hideCalendlyModal={hideCalendlyModal} showCalendlyModal={showCalendlyModal}
                    isCalendlyOpen={isCalendlyOpen} showContactModal={showContactModal}
                    hideContactModal={hideContactModal}
                    isContactOpen={isContactOpen}
            />
        </div>
    </>);
}

export default Home;
