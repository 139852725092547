import React from 'react'

// Context
const State = React.createContext();
const Dispatch = React.createContext();

//Lang reducer
const reducer = (state, action) => {
    switch (action.type) {
        case 'setLanguage':
            return {
                ...state,
                language: action.payload,
            }
        default:
            return state
    }
}

// Lang provider
const Provider = ({children}) => {
    const defaultState = {
        language: 'no',
    }
    const [state, dispatch] = React.useReducer(reducer, {...defaultState})

    return (
        <State.Provider value={state}>
            <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
        </State.Provider>
    )
}

// Export
export const Settings = {
    State,
    Dispatch,
    Provider
}
