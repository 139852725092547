import React, {useContext, useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollToPlugin} from "gsap/ScrollToPlugin"
import {Settings} from "../store";
import translations from '../data/lang.json';
import {setInputFilter} from "../hooks/helperFunctions";
import {NumericFormat} from 'react-number-format';
import Header from "./Header";
import {Parallax} from 'react-scroll-parallax';

const Banner = (props) => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const settingsState = useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const {isLoaded, investmentValue, updateInvestmentValue, setHideMain, hideMain} = props;
    const [lockAutoFocus, setLockAutoFocus] = useState(false);
    const [inputValidation, setInputValidation] = useState(false);
    const [lockIcon, setLockIcon] = useState(false);
    const button = useRef(null)
    let ref = useRef();
    const [separator, setSeparator] = useState(' ')
    useEffect(() => {
        if (!lockIcon) {
            window.bodymovin.loadAnimation({
                wrapper: button.current,
                animType: 'svg',
                loop: true,
                path: ''
            });
            setLockIcon(true)
        }
    }, [lockIcon]);


    useEffect(() => {
        gsap.set(elGroup('.word span'), {
            y: 100,
            position: 'relative',
            opacity: 0,
            transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
            display: 'inline-block'
        });
        gsap.set(elGroup('.lead, form, .badge'), {
            y: 10, position: 'relative', opacity: 0, transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
        });
        gsap.set(elGroup('img'), {scale: 1, translateX: '-50%', translateY: '-50%'});
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.block--hero', start: "top", end: "bottom", scrub: 1, invalidateOnRefresh: true
            },
        });
        tl.to(elGroup('img'), {
            scale: 1.1,
            translateY: '-55%'
        }, 'both');
        tl.to(elGroup('.hero-inner'), {
            y: 50
        }, 'both');
    }, []);

    useEffect(() => {
        if (isLoaded === true) {
            setTimeout(() => {
                gsap.to(elGroup('.word span')[0], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.05, opacity: 1});
                gsap.to(elGroup('.word span')[1], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.1, opacity: 1});
                gsap.to(elGroup('.word span')[2], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.15, opacity: 1});
                gsap.to(elGroup('.word span')[3], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.2, opacity: 1});
                gsap.to(elGroup('.word span')[4], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.25, opacity: 1});
                gsap.to(elGroup('.word span')[5], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.27, opacity: 1});
                gsap.to(elGroup('.word span')[6], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.32, opacity: 1});
                gsap.to(elGroup('.word span')[7], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.37, opacity: 1});
                gsap.to(elGroup('.word span')[8], {y: 0, ease: "expo.out", duration: 0.8, delay: 0.42, opacity: 1});
                gsap.to(elGroup('.lead, form, .badge'), {
                    y: 0, opacity: 1
                });
            }, 900)
        }
    }, [isLoaded]);

    useEffect(() => {
        ref.focus();
    }, [ref]);

    useEffect(() => {
        var magnets = document.querySelectorAll('.magnetic')
        var strength = 30
        magnets.forEach((magnet) => {
            magnet.addEventListener('mousemove', moveMagnet);
            magnet.addEventListener('mouseout', function (event) {
                gsap.to(event.currentTarget, 1, {x: 0, y: 0})
            });
        });

        function moveMagnet(event) {
            var magnetButton = event.currentTarget
            var bounding = magnetButton.getBoundingClientRect()
            gsap.to(magnetButton, 1, {
                x: (((event.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * strength,
                y: (((event.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * strength

            })
        }
    }, []);

    useEffect(() => {
        gsap.fromTo('#hide-on-scroll', {
            opacity: 1,
        }, {
            opacity: 0, scrollTrigger: {
                trigger: el.current,
                start: 0,
                end: window.innerHeight,
                scrub: true,
                invalidateOnRefresh: true
            },
        });
    }, []);

    useEffect(() => {
        if (inputValidation === true) {
            ref.focus();
        }
    }, [inputValidation]);
    const MAX_LIMIT = 1000000000000;


    const handleClick = (e) => {
        e.preventDefault()
        if (investmentValue === '' || investmentValue === 0 || investmentValue == undefined) {
            setInputValidation(true)
        } else {
            if (!hideMain) {
                gsap.to(window, 0, {
                    scrollTo: document.getElementById('banner').clientHeight,
                    ease: "none",
                    duration: 1,
                    delay: 0,
                    // onComplete: () => {
                    //     const el = document.querySelectorAll('.outer-wrapper');
                    //     el.forEach(box => {
                    //         box.classList.remove('no-scroll');
                    //     });
                    // }
                });
            }
            setHideMain(false)
        }
    }
    useEffect(() => {
        if (language === 'com') {
            setSeparator(',')
        } else {
            setSeparator(' ')
        }

    }, [language]);


    return (<>
        {/*<Parallax style={{minHeight:'100vh'}} translateY={[-40, 40]}>*/}
        <section data-section-theme={'default'} id="banner" ref={el}
                 className="block block--hero p-0 d-flex justify-content-center w-100">
            <a className="navbar-brand" href="#">
                <svg height="20" width="115" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 925 160">
                    <path
                        d="m783.232 86.247.068-79.729h-24.638l-.068 79.729a46.149 46.149 0 1 1-92.3 0h.006V6.518h-24.633l-.006 79.729c0 42.88 35.732 71.185 70.786 71.2 34.412.006 70.853-27.647 70.785-71.2ZM197.782 2.558a77.4 77.4 0 1 0 77.4 77.4 77.486 77.486 0 0 0-77.4-77.4Zm52.76 77.4a52.76 52.76 0 1 1-52.76-52.76 52.82 52.82 0 0 1 52.76 52.758ZM424.335 75.9a69.46 69.46 0 0 0-69.381-69.382v24.638A44.794 44.794 0 0 1 399.7 75.9Zm.165 78.654v-24.639a44.794 44.794 0 0 1-44.743-44.743h-24.644a69.46 69.46 0 0 0 69.387 69.382Zm41.045-69.334h24.638v69.127h-24.638zm112.79 0h24.638v69.127h-24.638zM305.439 6.518h24.638v147.829h-24.638zM6.365 154.347h25.442V31.339l75.826-.183V6.518H6.365v147.829z"
                        className="cls-1"/>
                    <path
                        d="M55.344 68.225h28.753v24.638H55.344zm810.981 0h28.753v24.638h-28.753zM534.099 97.149l77.054-77.054-17.421-17.422-59.633 59.632-59.632-59.632-17.422 17.422 77.054 77.054z"
                        className="cls-1"/>
                    <path
                        d="M818.171 154.347h100.464v-24.638l-75.826-.183V31.339l75.826-.183V6.518H818.171v147.829z"
                        className="cls-1"/>
                </svg>
            </a>
            <div className="back__image--1 block__media">
                <img src={'../media/banner-image.jpg'} alt=""/>
            </div>
            <div className="container h-100">
                <div id="hide-on-scroll" className="block__content gap-5 gap-lg-6">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-lg-10 col-xl-8">
                            <div className="d-flex flex-column gap-3 gap-lg-5">
                                <h1><span className="word"><span>Explore</span></span> <span
                                    className="word"><span>the</span></span> <span
                                    className="word"><span>negative</span></span> <span
                                    className="word"><span>impacts</span></span> <span
                                    className="word"><span>of</span></span> <span
                                    className="word"><span>your</span></span> <span
                                    className="word"><span>investments</span></span></h1>
                                <p className="lead">Quantify your impact and see the drivers and how they affect the
                                    UN Sustainable Development Goals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <form className={inputValidation ? 'invalid form-wrapper' : 'valid form-wrapper'} action=""
                                  onSubmit={() => {
                                      if (investmentValue === '' || investmentValue === 0) return false;
                                  }}>
                                <label className="mb-3 mb-lg-0" htmlFor={"investmentValue"}>Enter investment amount
                                    ({translations[language].general.currency}):</label>
                                <div className="d-flex align-items-center">
                                    <div className="position-relative input-wrapper d-flex align-items-center">
                                        <div>
                                            <NumericFormat
                                                getInputRef={(inputRef) => {
                                                    ref = inputRef;
                                                    if (!lockAutoFocus) {
                                                        if (isLoaded) {
                                                            setTimeout(() => {
                                                                if (ref == null) return;
                                                                ref.focus()
                                                                setLockAutoFocus(true);
                                                            }, 1200)
                                                        }
                                                    }
                                                }}
                                                pattern="[0-9]*"
                                                thousandSeparator={separator}
                                                id="investmentValue"
                                                required
                                                onValueChange={(values, sourceInfo) => {
                                                    updateInvestmentValue(values)
                                                    if (values.floatValue == 0) {
                                                        setInputValidation(true)
                                                    } else {
                                                        setInputValidation(false)
                                                    }
                                                }}
                                                isAllowed={(values, sourceInfo) => {
                                                    const {value} = values;
                                                    return value < MAX_LIMIT;
                                                }}
                                                suffix={' ' + translations[language].general.currency}
                                            />
                                        </div>
                                        <div className="magnetic">
                                            <button ref={button} type={"submit"} className="submit btn--svg"
                                                    onClick={handleClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-arrow-down">
                                                    <line x1="12" y1="4" x2="12" y2="20"></line>
                                                    <polyline points="18 14 12 20 6 14"></polyline>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {inputValidation && ''}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*</Parallax>*/}
    </>)
}
export default Banner;
