import React, {useEffect} from "react";
import gsap from 'gsap';
import {scrollToSection} from "../hooks/helperFunctions";


const ScrollspyNav = (props) => {
    const {isLoaded} = props;

    useEffect(() => {
        window.onresize = () => {
            let sectionsArray = gsap.utils.toArray("section");
            sectionsArray.forEach(function (e, index) {
                e.setAttribute('data-distance', e.getBoundingClientRect().top)
                e.setAttribute('data-height', e.clientHeight)
                e.setAttribute('data-index', index)
            });
        }
    }, []);

    useEffect(() => {
        let sectionsArray = gsap.utils.toArray("section");
        if (isLoaded === true) {
            window.onscroll = () => {
                let distanceFromTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
                let bannerHeight = document.getElementById('banner').clientHeight;
                let investingReturnHeight = document.getElementById('investing_return').clientHeight;
                if (distanceFromTop >= bannerHeight - 1) {
                    document.getElementsByClassName('scrollspy')[0].classList.remove('disabled')
                } else {
                    document.getElementsByClassName('scrollspy')[0].classList.add('disabled')
                }
                sectionsArray.forEach(function (e, index) {
                    let elementScrollTop = parseFloat(e.getAttribute('data-distance'))
                    let elementHeight = e.clientHeight
                    let elementID = e.getAttribute('id')
                    if (elementScrollTop - 10 < distanceFromTop && distanceFromTop < (parseFloat(elementScrollTop) - 10 + parseFloat(elementHeight))) {
                        if (elementID === 'investments') {
                            if (distanceFromTop < bannerHeight + investingReturnHeight ) {
                                elementID = 'investing_return'
                            } else {
                                elementID = 'negativeImpact'
                            }
                        }
                        if ((window.innerHeight + distanceFromTop) >= document.body.offsetHeight) {
                            elementID = 'footer'
                        }
                        gsap.set('.scrollspy span', {className: ""});
                        gsap.set('span[data-id="' + elementID + '"]', {className: "active"});
                    }
                });
            }
            sectionsArray.forEach(function (e, index) {
                e.setAttribute('data-distance', e.getBoundingClientRect().top)
                e.setAttribute('data-height', e.clientHeight)
                e.setAttribute('data-index', index)
            });
        }
    }, [isLoaded])
    useEffect(() => {
        let svgContainer = document.querySelector('.bodymovinanim');
        window.bodymovin.loadAnimation({
            wrapper: svgContainer,
            animType: 'svg',
            loop: true,
            path: '../media/scrollDown.json'
        });
    }, []);


    return (
        <div className="scrollspy--holder">
            <div className="scrollspy disabled">
                <span onClick={() => {
                    scrollToSection('#banner')
                }}></span>
                <span data-id={'investing_return'} className="active" onClick={() => {
                    scrollToSection('#investing_return')
                }}></span>
                <span data-id={'negativeImpact'} onClick={() => {
                    scrollToSection('#negativeImpact')
                }}></span>
                <span data-id={'sector_cards'} onClick={() => {
                    scrollToSection('#sector_cards')
                }}></span>
                <span data-id={'continent_cards'} onClick={() => {
                    scrollToSection('#continent_cards')
                }}></span>
                <span data-id={'sdg_cards'} onClick={() => {
                    scrollToSection('#sdg_cards')
                }}></span>
                <span data-id={'take_action_title'} onClick={() => {
                    scrollToSection('#take_action_title')
                }}></span>
                <span data-id={'ta1'} onClick={() => {
                    scrollToSection('#ta1')
                }}></span>
                <span data-id={'ta2'} onClick={() => {
                    scrollToSection('#ta2')
                }}></span>
                <span data-id={'ta3'} onClick={() => {
                    scrollToSection('#ta3')
                }}></span>
                <span data-id={'footer'} onClick={() => {
                    scrollToSection('#footer')
                }}></span>
            </div>
            <div className="bodymovinanim"></div>
        </div>
    )
}

export default ScrollspyNav;
