import React, {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import translations from '../data/lang.json';
import sdgData from '../data/sdg-data.json';
import Modal from "react-bootstrap/Modal";
import {Settings} from "../store";
import SDG from "./SDG";
import {SpreadGrid} from '../hooks/spreadGrid';
import {MasonryGrid, JustifiedGrid, FrameGrid, PackingGrid} from "@egjs/react-grid";
import {getNegativeImpactValue} from "../hooks/helperFunctions";
import Card from "./Card";
import SDGModal from "./SDGModal";
import SDGDetailsModal from "./SDGDetailsModal";

const SDGs = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {investmentValue, negativeCalculation} = props;
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const [activeSDG, setActiveSDG] = useState(sdgData['SDG3']);
    const [activeSDGSector, setActiveSDGSector] = useState(0);
    const [totalNegativeImpact, setTotalNegativeImpact] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDetails, setIsOpenDetails] = useState(false);
    const [activeSDGCard, setActiveSDGCard] = useState(3);
    const [totalSDGImpactValue, setTotalSDGImpactValue] = useState(0);
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const showModal = () => {
        setIsOpen(true);
    };
    const hideModal = () => {
        setIsOpen(false);
        [...elGroup('.grid')].forEach(grid => new SpreadGrid(grid));
        gsap.to(elGroup('.card.grid__item'), {
            transform: 'translate(0,0)'
        })
    };
    const showModalDetails = () => {
        setIsOpenDetails(true);
    };
    const hideModalDetails = () => {
        setIsOpenDetails(false);
    };
    const [SDGCards, setSDGCards] = useState(['SDG3', 'SDG6', 'SDG7', 'SDG11', 'SDG13', 'SDG14', 'SDG15']);
    useEffect(() => {
        let sum = 0;
        SDGCards.forEach((item, index) => {
            sum += sdgData[item].totalSectorImpact
        })
        setTotalSDGImpactValue(sum)
    }, []);

    const staticValue = {
        height: 216982,
        maxwh: 200,
        fontDivider: 7,
        maxVal: 216982,
        largestShare: '<span class="word"><span>Climate</span></span> <span class="word"><span>action</span></span>',
    }

    useEffect(() => {
        gsap.set(elGroup('.grid__item'), {
            scale: 0, rotation: 0, x: 0, y: 0, opacity: 0
        });
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current, start: "top 50%", end: "bottom", scrub: false
            },
        })
        let delay = 0.05
        SDGCards.forEach((item, index) => {
            tl.to(elGroup('.grid__item')[index], {
                opacity: 1, delay: delay, scale: 1, duration: 0.5
            }, 'same')

            delay += 0.05;
        })
    }, []);
    useEffect(() => {
        [...elGroup('.grid')].forEach(grid => new SpreadGrid(grid));
    }, []);

    useEffect(() => {
        gsap.set(elGroup('.word span'), {
            y: 100,
            position: 'relative',
            opacity: 0,
            transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
            display: 'inline-block'
        });
        let delay = 0.05;
        gsap.utils.toArray(elGroup('.word span')).forEach(function (e, index) {
            gsap.to(elGroup('.word span')[index], {
                scrollTrigger: {
                    trigger: el.current,
                    start: "top 70%",
                    scrub: false,
                },
                y: 0,
                ease: "expo.out",
                duration: 0.8,
                delay,
                opacity: 1
            });
            delay += 0.05;
        });
    }, []);

    return (<section ref={el} id={'sdg_cards'}
                     className="block block--cards"
                     data-spy={'#sdg_cards'} data-section-theme={'light'}>
        <div className="block__content">
            {/*<div className="position-absolute background-number opacity-25">{translations[language].general.currency} {negativeCalculation.toLocaleString(language, {maximumFractionDigits: 0})}</div>*/}

            <div className="container d-flex flex-column gap-5 gap-lg-6">
                <div className="row">
                    <div className="col-lg-6 formue-animation d-flex flex-column gap-5">
                        <h2>SDG's</h2>
                        <p className="lead"><i><strong
                            dangerouslySetInnerHTML={{__html: staticValue.largestShare}}></strong></i><br/> <span
                            className="word"><span>– is the UN Sustainable</span></span> <span
                            className="word"><span> Development</span></span> <span
                            className="word"><span>Goal affected</span></span> <span
                            className="word"><span>the most by the negative</span></span> <span
                            className="word"><span>impacts.</span></span>
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className={"grid grid--sdg d-lg-grid flex-row"}
                             style={{
                                 gridTemplateColumns: 'repeat(14, 1fr)',
                                 gridTemplateRows: 'repeat(13, 1fr)',
                                 gap: 24
                             }}

                             data-duration="1"
                             data-ease="elastic.out(0.5)"
                             data-scale="1.4"
                             data-max-rotation="20"
                             data-spread="150"
                             data-max-distance="700"
                        >
                            {SDGCards.map((item) => {
                                let SDGData = sdgData[item];
                                return <SDG order={SDGData.order} setActiveSDGCard={setActiveSDGCard} grid={SDGData.grid}
                                            showModal={showModal} key={SDGData.id} item={item}
                                            setActiveSDG={setActiveSDG}
                                            activeSDG={activeSDG} totalSDGImpactValue={totalSDGImpactValue}/>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SDGModal setActiveSDGSector={setActiveSDGSector}
                  showModalDetails={showModalDetails}
                  setActiveSDG={setActiveSDG}
                  isOpen={isOpen}
                  hideModal={hideModal}
                  activeSDG={activeSDG}
                  SDGCards={SDGCards}
                  totalSDGImpactValue={totalSDGImpactValue}
                  negativeCalculation={negativeCalculation}/>
        <SDGDetailsModal activeSDGSector={activeSDGSector}
                         activeSDG={activeSDG}
                         totalSDGImpactValue={totalSDGImpactValue}
                         isOpenDetails={isOpenDetails}
                         negativeCalculation={negativeCalculation}
                         hideModalDetails={hideModalDetails}/>
    </section>);
}

export default SDGs;
