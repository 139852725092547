import React, {useRef} from "react";
import {Settings} from "../store";
import ContinentItem from "./ContinentItem";

const ContinentsTab = (props) => {
    const {
        activeSDG,
        negativeImpactByType,
        setActiveSDGSector,
        showModalDetails
    } = props;
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    return (
        <div ref={el} className="grid d-block d-lg-grid grid--tabs" style={{gridTemplateColumns: 'repeat(5, 1fr)', gridTemplateRows:'repeat(4, 1fr)', gap: 10, height: 500}} >
            {activeSDG.continents.map(item => <ContinentItem  grid={item.grid} key={item.totalNegativeImpact} showModalDetails={showModalDetails}
                                                             setActiveSDGSector={setActiveSDGSector} item={item}
                                                             activeSDG={activeSDG}
                                                             negativeImpactByType={negativeImpactByType}
                                                             language={language}/>)}
        </div>
    )
}

export default ContinentsTab;
