import React, {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {mouseMoveHandler, throttled} from "../hooks/helperFunctions";
import {Settings} from "../store";
import {Parallax} from "react-scroll-parallax"

const TakeActionStep1 = () => {
    gsap.registerPlugin(ScrollTrigger);
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const [statusParallax, setStatusParallax] = useState(false);
    useEffect(() => {
        // gsap.set(elGroup('.move-element'), {
        //     opacity: 0
        // });

        gsap.set(elGroup('#main-circle'), {
            opacity: 0
        });

    }, []);

    useEffect(() => {
        gsap.to(elGroup('#main-circle'), {
            opacity: 1,
            scrollTrigger: {
                trigger: el.current,
                start: () => {
                    return "top 30%"
                },
                scrub: false
            }
        })

    }, []);

    useEffect(() => {
        const movableElementsWrapper = elGroup('.diagram')[0];
        const movableElements = elGroup('.move-element');
        movableElementsWrapper.onmousemove = throttled(200, (e) => {
            mouseMoveHandler(e, movableElements)
        });
    }, []);
    useEffect(() => {
        if (window.innerWidth < 991) {
            setStatusParallax(prevState => true)

        } else {
            setStatusParallax(prevState => false)

        }
    }, []);

    window.onresize = () => {
        if (window.innerWidth < 991) {
            setStatusParallax(prevState => true)

        } else {
            setStatusParallax(prevState => false)

        }

    }

    return (
        <>
            <section data-section-theme="purple" data-spy={'#ta1'} id={'ta1'} ref={el}
                     className="block block--rounded-top block--takeaction">
                <div className="block__content pt-5">
                    <div className="container">
                      <div className="text-center mb-6 d-block d-lg-none">
                        <h4 className="preheader mb-3">Take action - 01</h4>
                      </div>
                        <div className="row align-items-center justify-content-between flex-column-reverse flex-lg-row gap-6 gap-lg-0">
                            <div className="col-lg-6">
                                <div className="formue-animation d-flex flex-column gap-5">
                                    <div>
                                      <h4 className="preheader mb-3 d-none d-lg-block">Take action - 01</h4>
                                      <h2>Your <i>wealth footprint</i> consists of more than just your investments
                                      </h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-9 col-md-12">
                                            <p>The way you and your family live, your portfolio of investments and even
                                                the business you may own have an impact. Get to know your total wealth
                                                footprint and make the right adjustments for you.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 text-center align-items-center diagram position-relative">
                                <svg id="main-circle" className="block__base" width="600" height="600"
                                     viewBox="0 0 638 638">
                                    <circle opacity="1" cx="319" cy="319" r="319"/>
                                </svg>
                                <div className="position-relative d-flex floating-images align-items-center gap-3">

                                    <div className="formue-animation floating-images__item" data-duration={.5}>
                                        <Parallax style={{transform: statusParallax && 'none'}}
                                                  disabled={statusParallax} speed={-8}>
                                            <div className="inner text-start">
                                                <img
                                                    src={'../media/img-4.jpg'}
                                                    alt=""/>
                                                <span
                                                    className="label">Investments</span>
                                            </div>
                                        </Parallax>
                                    </div>
                                    <div className="formue-animation floating-images__item" data-delay={0.3}
                                         data-duration={.5}>
                                        <Parallax style={{transform: statusParallax && 'none'}}
                                                  disabled={statusParallax} speed={0}>
                                            <div className="inner text-start">
                                                <img
                                                    src={'../media/lifestyle-1.jpg'}
                                                    alt=""/>
                                                <span
                                                    className="label">Lifestyle</span>
                                            </div>
                                        </Parallax>
                                    </div>
                                    <div className="formue-animation floating-images__item" data-duration={.5}
                                         data-delay={.6}>
                                        <Parallax style={{transform: statusParallax && 'none'}}
                                                  disabled={statusParallax} speed={6}>
                                            <div className="inner text-lg-end text-start">
                                                <img
                                                    src={'../media/business-4.jpg'}
                                                    alt=""/>
                                                <span
                                                    className="label">Business</span>
                                            </div>
                                        </Parallax>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TakeActionStep1;
