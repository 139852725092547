import React, {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Card from "./Card";
import WorldMapSVG from "./WorldMapSVG";
import {SpreadGrid} from '../hooks/spreadGrid';

const Cards = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {negativeCalculation} = props;
    const [activeID, setActiveID] = useState(null);
    const [totalImpactValue, setTotalImpactValue] = useState(0);
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);

    const allLocations = [

        {
            id: 1,
            name: 'Europe',
            companies: 430,
            img: 'samuel-regan-asante-xDBT-jnEbeI-unsplash.jpg',
            totalNegativeImpact2020: 151202973290,
            grid: "1 / 5 / 11 / 8"
        },
        {
            id: 2,
            name: 'North America',
            companies: 665,
            img: 'tom-coe-A7KD1kdXD-o-unsplash.jpg',
            totalNegativeImpact2020: 146925553567,
            grid: "1 / 8 / 11 / 11"
        },
        {
            id: 3,
            name: 'Asia',
            companies: 316,
            img: 'charles-postiaux-WOw3Pcdmw4I-unsplash.jpg',
            totalNegativeImpact2020: 59115166846,
            grid: "1 / 1 / 5 / 5"
        },
        {
            id: 4,
            name: 'Oceania',
            companies: 66,
            img: 'annie-spratt-pbrQvuKJQf8-unsplash.jpg',
            totalNegativeImpact2020: 44532222490,
            grid: "5 / 1 / 8 / 5"
        },
        {
            id: 5,
            name: 'South America',
            companies: 1,
            img: 'marcelo-cidrack-2AFAqAhZprU-unsplash.jpg',
            totalNegativeImpact2020: 9877700,
            grid: "8 / 3 / 11 / 5"
        }
    ]
    const staticValue = {
        'all': {
            'height': 35925,
            'maxwh': 1500,
            'fontDivider': 7
        },
        largestShare: '<span class="word"><span>Europe</span></span>',
    }

    useEffect(() => {
        elGroup('.grid').forEach((item) => {
            gsap.set(item, {
                height: item.clientHeight
            })
        })
        gsap.set(elGroup('.grid__item'), {
            scale: 0,
            rotation: 0,
            x: 0,
            y: 0,
            opacity: 0
        });
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: "top 50%",
                end: "bottom",
                scrub: false
            },
        })
        let delay = 0.05
        let sum = 0;
        allLocations.forEach((item, index) => {
            sum += item.totalNegativeImpact2020
            tl.to(elGroup('.grid__item')[index], {
                opacity: 1,
                scale: 1,
                delay: delay,
                duration: 0.5,
                onComplete: () => {
                    if (index === 0) {
                        if (window.innerWidth > 992) {
                            elGroup('.grid__item')[index].classList.add('active')
                            elGroup('.grid__item')[index].click();
                        }
                    }
                }
            }, 'same')

            delay += 0.05;
        })
        setTotalImpactValue(sum)
    }, [window.innerWidth]);

    useEffect(() => {
        if (window.innerWidth > 992) {
            [...elGroup('.grid')].forEach(grid => new SpreadGrid(grid));
        }
    }, [window.innerWidth]);

    useEffect(() => {
        gsap.set(elGroup('.word span'), {
            y: 100,
            position: 'relative',
            opacity: 0,
            transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
            display: 'inline-block'
        });
        let delay = 0.05;
        gsap.utils.toArray(elGroup('.word span')).forEach(function (e, index) {
            gsap.to(elGroup('.word span')[index], {
                scrollTrigger: {
                    trigger: el.current,
                    start: "top 70%",
                    scrub: false,
                },
                y: 0,
                ease: "expo.out",
                duration: 0.8,
                delay,
                opacity: 1
            });
            delay += 0.05;
        });
    }, []);

    return (
        <section id={'continent_cards'} data-section-theme={'red'} ref={el} className="block block--cards red s1"
                 data-spy={'#continent_cards'}>
            {/*<div className="block__media" style={{backgroundImage: "url(../media/svg/world.svg"}}></div>*/}

            <div className="block__content">
                <div className="container d-flex flex-column gap-5 gap-lg-6 position-relative zIndex-1">
                    <div className="row">
                        <div className="col-lg-6 formue-animation d-flex flex-column gap-5">
                            <h2>Continents</h2>
                            <p className="lead"><i><strong
                                dangerouslySetInnerHTML={{__html: staticValue.largestShare}}></strong></i><br/> <span
                                className="word"><span>– is </span></span> <span
                                className="word"><span>the continent</span></span> <span
                                className="word"><span>with</span></span> <span
                                className="word"><span>companies</span></span> <span
                                className="word"><span>that are responsible</span></span> <span
                                className="word"><span> for the largest</span></span> <span className="word"><span> share ({(100 * allLocations[0].totalNegativeImpact2020 / totalImpactValue).toFixed(0)} %)</span></span>
                                <span
                                    className="word"><span> of the</span></span> <span className="word"><span>negative impacts.</span></span>
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="position-relative">
                                <div className="grid d-block d-lg-grid" style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(10, 1fr)',
                                    gridTemplateRows: 'repeat(10, 1fr)',
                                    gap: 24
                                }}
                                     data-duration="1"
                                     data-ease="elastic.out(0.5)"
                                     data-scale="1.3"
                                     data-max-rotation="20"
                                     data-spread="150"
                                     data-max-distance="700"
                                >
                                    {allLocations.map((item, index) => {
                                        let percent = 100 * item.totalNegativeImpact2020 / totalImpactValue;

                                        if (percent >= 1) {
                                            percent = percent.toFixed(0);
                                        } else {
                                            percent = percent.toFixed(3);
                                        }

                                        return <Card img={item.img} percent={percent} activeID={activeID}
                                                     setActiveID={setActiveID}
                                                     cardID={item.id}
                                                     grid={item.grid}
                                                     key={item.id}
                                                     name={item.name}
                                                     value={item.value} companies={item.companies}
                                                     negativeCalculation={negativeCalculation}
                                                     totalNegativeImpact={item.totalNegativeImpact2020}
                                                     type={'continent_cards'}
                                        />
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="world-map">
                    <WorldMapSVG/>
                </div>
            </div>
        </section>
    );
}

export default Cards;
