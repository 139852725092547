import React, {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import translations from '../data/lang.json';
import {Settings} from "../store";
import {SmoothCorners} from 'react-smooth-corners'
import Modal from "react-bootstrap/Modal";
import {langSeparator} from "../hooks/helperFunctions";

const Card = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {
        companies,
        name,
        cardID,
        activeID,
        setActiveID,
        percent,
        img,
        negativeCalculation,
        totalNegativeImpact,
        grid,
        type,
        order
    } = props;

    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const [isOpenDetails, setIsOpenDetails] = useState(false);

    const showModalDetails = () => {
        setIsOpenDetails(true);
    };
    const hideModalDetails = () => {
        setIsOpenDetails(false);

        const els = document.querySelectorAll('#' + type + ' .card.grid__item');
        els.forEach((item) => {
            item.classList.remove("active");
        })
    };

    let image, noImage = '';
    if (img) {
        image = React.createElement(
            "img",
            {src: "../media/" + img, className: "position-absolute w-100"},
            null,
        );
    } else {
        noImage = ' no-image'
    }
    let classes = 'card grid__item';
    if (cardID === activeID) {
        classes = 'card grid__item active' + noImage;
    }
    if (order === 1) {
        classes += ' order-1'
    }

    return (<>
            <div style={{gridArea: grid}}
                 onClick={() => {
                     if (window.innerWidth < 992) {
                         showModalDetails();
                         return
                     }
                     if (cardID === activeID) {
                         setActiveID(null)
                     } else {
                         setActiveID(cardID)
                     }
                 }}
                 data-id={cardID}
                 className={classes}>

                {image}
                <div className={'grid__item__inner'}>
                    <div className="overlay-fill" style={{width: percent + '%'}}></div>
                    <span className="label">{name}</span>
                    <div className="details">
                    <span className="d-flex mw-100 justify-content-between mb-0">
                        <span>Companies</span> <span>{companies}</span>
                    </span>
                        <hr className="my-2"/>
                        <span className="d-flex mw-100 justify-content-between mb-0">
                        <span>Impact</span>
                        <span> - {(percent * negativeCalculation / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</span>
                    </span>
                        <hr className="my-2"/>
                        <span className="d-flex mw-100 justify-content-between mb-0">
                        <span>Total</span>
                        <span> - {(totalNegativeImpact * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency}</span>
                    </span>
                    </div>
                    <h3 className="percentage">{percent}</h3>
                    <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M7.15 21.1q-.375-.375-.375-.888 0-.512.375-.887L14.475 12l-7.35-7.35q-.35-.35-.35-.875t.375-.9q.375-.375.888-.375.512 0 .887.375l8.4 8.425q.15.15.213.325.062.175.062.375t-.062.375q-.063.175-.213.325L8.9 21.125q-.35.35-.862.35-.513 0-.888-.375Z"/></svg></span>
                </div>
            </div>
            <Modal size="sm" dialogClassName={'modal-fullscreen-md-down'}
                   className={'modal--sectors ' + type}
                   backdrop={true}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpenDetails} onHide={() => {
                hideModalDetails();
            }}
            >
                <div className="overlay-background"></div>
                <div className="modal-header">
                    <button onClick={hideModalDetails} type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column gap-5 w-100">
                        <div>
                            <h4>{name}</h4>
                            {/*{<p className="small mt-2">– Sectors in 2022</p>*/}
                        </div>
                        <div className="box d-flex flex-column gap-4">
                            <div className="d-flex justify-content-between flex-column gap-1">
                                <h6>Based on investment:</h6>
                                <span>
                            - {(percent * negativeCalculation / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}
                        </span>
                            </div>
                            <div className="d-flex justify-content-between flex-column gap-1">
                                <h6>Companies:</h6>
                                <span>{companies}</span>
                            </div>
                            <div className="d-flex justify-content-between flex-column gap-1">
                                <h6>Total impact:</h6>
                                <span>- {(totalNegativeImpact * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Card;
