import translations from "../data/lang.json";
import React, {useRef, useState} from "react";
import {gsap} from "gsap";
import {langSeparator} from "../hooks/helperFunctions";

const SectorItem = (props) => {
    const {item, activeSDG, language, totalSDGImpactValue, showModalDetails, setActiveSDGSector, index, grid} = props;
    const [activeItem, setActiveItem] = useState(false)
    const totalImpactIN2020 = item.totalNegativeImpact;
    const totalImpactIn2020Procent = (totalImpactIN2020 * 100) / activeSDG.totalSectorImpact;
    const el = useRef(null);

    return (<div style={{gridArea: grid}} ref={el} onClick={(e) => {
        e.currentTarget.classList.add('active')
        document.body.classList.toggle('selected')
        showModalDetails()
        setActiveSDGSector(item);
    }} className={"sectors__item"}
    >
        <div className="content">
            <div className="overlay-fill" style={{width: totalImpactIn2020Procent + '%'}}></div>
            <div className="base-info">
                <span className="label">{item.name}</span>
                <h3 className="percentage">{totalImpactIn2020Procent.toFixed(0)}</h3>
            </div>
            <div className="details">
                All sectors in
                2022: {(totalImpactIN2020 * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency}<br/>
                Based on investment :
                - {(totalSDGImpactValue * totalImpactIn2020Procent / 100).toFixed(0)} {translations[language].general.currency}<br/>
                Companies : {item.companies}

            </div>
        </div>
    </div>)
}

export default SectorItem;
