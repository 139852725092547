import React, {useEffect, useRef, useState, useContext} from "react";
import '../hooks/nlform';
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Settings} from "../store";
import translations from "../data/lang.json";
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import {Parallax} from 'react-scroll-parallax';
import CountUp from 'react-countup';

import useFitText from "use-fit-text";
import {langSeparator} from "../hooks/helperFunctions";

const InvestingReturn = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {investmentValue, negativeCalculation, totalNegativeImpact2020} = props;
    const settingsState = useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    const button1 = useRef(null)
    const button2 = useRef(null)
    const elGroup = gsap.utils.selector(el);
    const [countupNumber, setCountupNumber] = useState(0);
    const [finalCalc, setFinalCalc] = useState((investmentValue * 0.2235));
    const [lockIcon, setLockIcon] = useState(false)
    const [numberBlockWidth, setNumberBlockWidth] = useState(window.innerWidth - 100)
    const [separator, setSeparator] = useState('&nbsp;')


    const {fontSize, ref} = useFitText({
        maxFotnSize: 80,
        minimumFontSize: 40
    });
    useEffect(() => {
        if (!lockIcon) {
            window.bodymovin.loadAnimation({
                wrapper: button1.current,
                animType: 'svg',
                loop: true,
                path: '../media/arrowDown.json'
            });
            window.bodymovin.loadAnimation({
                wrapper: button2.current,
                animType: 'svg',
                loop: true,
                path: '../media/arrowDown.json'
            });
            setLockIcon(true)
        }
    }, [lockIcon]);

    const updateNumber = () => {
        setCountupNumber(finalCalc)
    }
    useEffect(() => {
        setFinalCalc((investmentValue * 0.2235))
    }, [investmentValue]);

    useEffect(() => {
        gsap.set('#investing_return h2', {
            opacity: 0
        })
    }, []);

    const easingFn = (t, b, c, d) => {
        const ts = (t /= d) * t;
        const tc = ts * t;
        return b + c * (tc + -3 * ts + 3 * t);
    };

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: window.innerHeight / 2.5,
                end: "bottom",
                invalidateOnRefresh: true
            },
        });
        tl.call(updateNumber, 0);
        tl.to('#investing_return h2', {
            opacity: 1
        })
        //
        // gsap.timeline({
        //     scrollTrigger: {
        //         trigger: elGroup('.return-number'),
        //         start: "top 70%",
        //         invalidateOnRefresh: true,
        //         onEnter: () => {
        //             elGroup('.return-number')[0].classList.add('active')
        //         },
        //         onLeaveBack: () => {
        //             elGroup('.return-number')[0].classList.remove('active')
        //         }
        //     },
        // });
    }, [finalCalc]);

    useEffect(() => {

        gsap.to(elGroup('#negativeImpact'), {
            scrollTrigger: {
                trigger: '#investing_return',
                start: () => {
                    return document.getElementById('banner').clientHeight
                },
                end: () => {
                    return document.getElementById('banner').clientHeight + (window.innerHeight * 1.5)
                },
                scrub: true,
                invalidateOnRefresh: true
            },
            clipPath: 'inset(0% 0px 0px)'
        });
    }, []);

    function handleResize() {
        setNumberBlockWidth(window.innerWidth - 100)
    }

    window.addEventListener('resize', handleResize)

    useEffect(() => {
        if (language === 'com') {
            setSeparator(',')
        } else {
            setSeparator('&nbsp;')
        }

    }, [language]);

    return (<>
        <section data-section-theme={'default'} id="investments" ref={el} data-spy="#investing_return"
                 className="block block--impact block--bg sticky-container ignore-theme">
            <div className="sticky-element">
                <div id="investing_return" className="block__content one">
                    <div className="block__media" style={{backgroundImage: "url(../media/"}}></div>
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-12 return-number align-items-center d-flex justify-content-center flex-column gap-4">
                                <div>
                                    <p className="impact-label lead">Investing <i> {investmentValue.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</i> in
                                        the <i>MSCI World Index<sup>*</sup> in 2021</i> would give you a return of:
                                    </p>
                                    <div className="formue-animation">
                                        <div className="d-flex align-items-center gap-1">

                                            {/*<CountUp*/}
                                            {/*    start={0}*/}
                                            {/*    end={countupNumber}*/}
                                            {/*    duration={2}*/}
                                            {/*    separator=" "*/}
                                            {/*    decimals={0}*/}
                                            {/*    useEasing={true}*/}
                                            {/*    // prefix="+"*/}
                                            {/*    suffix=""*/}
                                            {/*    easingFn={easingFn}*/}
                                            {/*    enableScrollspy={true}*/}
                                            {/*>*/}
                                            {/*    {({countUpRef, start}) => (*/}
                                            {/*        <h2 className="large currency" ref={countUpRef}/>*/}
                                            {/*    )}*/}
                                            {/*</CountUp>*/}

                                            <h2 className="large">
                                            <span className="d-flex justify-content-center align-items-center" ref={ref}
                                                  style={{fontSize, width: numberBlockWidth}}>
                                                <svg className="pos-value" xmlns="http://www.w3.org/2000/svg"
                                                     height="64px"
                                                     viewBox="0 0 24 24"
                                                     width="64px" fill="#FFFFFF">
                                                <path className="no-fill" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
                                                </svg>
                                                <CountUp
                                                    start={0}
                                                    end={countupNumber}
                                                    duration={2}
                                                    separator={separator}
                                                    decimals={0}
                                                    useEasing={true}
                                                    // prefix="+"
                                                    suffix=""
                                                    easingFn={easingFn}
                                                    enableScrollspy={true}
                                                >
                                                    {({countUpRef, start}) => (
                                                        <span ref={countUpRef}/>
                                                    )}
                                                </CountUp>
                                                <span
                                                    className="currency-suffix">{translations[language].general.currency}</span>
                                            </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="block__content__footer">

                            <button ref={button1} type="button" className="btn btn--circle btn-light btn--svg"
                                    onClick={() => {
                                        gsap.to(window, 0, {
                                            scrollTo: document.getElementById('banner').clientHeight + (document.getElementById('investing_return').clientHeight * 1.5),
                                            ease: "none",
                                            duration: 2,
                                            delay: 0
                                        });
                                    }}></button>

                            <p className="small fine-print"><sup>*</sup>MSCI World is a stock market index constisting
                                of
                                approximately 1 500 companies throughout the world. It is used as a common benchmark
                                for the global stock market.</p>

                        </div>
                    </div>
                </div>
                <div id="negativeImpact" className="block__content two">
                    <div className="block__media block__media--fade"
                         style={{backgroundImage: "url(../media/daniel-leone-v7daTKlZzaw-unsplash.jpg)"}}></div>
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-12 align-items-center d-flex justify-content-center flex-column gap-4">
                                <div>
                                    <p className="impact-label lead">The same investment had a <strong><i>negative
                                        impact</i></strong> worth:</p>
                                    <div className="d-flex align-items-center gap-1">

                                        {/*<h2 className="large currency">*/}
                                        {/*    {negativeCalculation.toLocaleString(language, {maximumFractionDigits: 0})}*/}
                                        {/*</h2>*/}
                                        <h2 className="large">
                                            <span className="d-flex justify-content-center align-items-center" ref={ref}
                                                  style={{fontSize, width: numberBlockWidth}}>
                                                 <svg className="neg-value" xmlns="http://www.w3.org/2000/svg"
                                                      height="64px"
                                                      viewBox="0 0 24 24"
                                                      width="64px"
                                                      fill="#FFFFFF">
                                                     <path d="M0 0h24v24H0z" className="no-fill" fill="none"/>
                                                     <path d="M19 13H5v-2h14v2z"/>
                                                 </svg>
                                                <span> {negativeCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})}</span>
                                                <span
                                                    className="currency-suffix">{translations[language].general.currency}</span>
                                            </span>
                                        </h2>
                                    </div>

                                    <p className="subheader">Total negative impact for MSCI World<sup>*</sup>:
                                        <strong>{totalNegativeImpact2020}</strong>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="block__content__footer">

                            <button ref={button2} type="button" className="btn btn--circle btn--svg btn-light"
                                    onClick={() => {
                                        gsap.to(window, 0, {
                                            scrollTo: '#sector_cards',
                                            ease: "none",
                                            duration: 1,
                                            delay: 0
                                        });
                                    }}></button>

                            <p className="small fine-print"><sup>*</sup>MSCI World is a stock market index constisting
                                of
                                approximately 1 500 companies throughout the world. It is used as a common benchmark
                                for the global stock market.</p>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>);
}

export default InvestingReturn;
